import { BaseModel } from "./base-model";

export class BasePriceCatalogMinimumModel extends BaseModel {
  branch_code: number = 0;
  department_code: number = 0;
  section_code: number = 0;
  category_code: number = 0;
  subcategory_code: string = "";
  mesorregion_code: number = 0;
  product_code: number = 0;
  year_week: string = "";

  price_minimum?: number;
  user_id?: number;
  branch_type?: string;
  num_level?: number;
}
